import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Segment, Image, List, Header, Button,
} from 'semantic-ui-react';

const Introduction = () => {
  const history = useHistory();
  return (
    <div>
      <Segment id="introduction">
        <div className="description">
          <Header>Booking expert interviews is as easy as:</Header>
          <List ordered>
            <List.Item>Set your default availability for Dexter meetings</List.Item>
            <List.Item>Request a call with experts of your choice</List.Item>
            <List.Item>Call the conference line provided at the scheduled time</List.Item>
          </List>
        </div>
        <Image
          centered
          size="big"
          src={`${process.env.REACT_APP_AWS_S3_URL}/introduction.png`}
        />
      </Segment>
      <div>
        <Button className="basic-btn" onClick={() => history.push('/tours/welcome')} content="<" />
        <Button
          floated="right"
          className="basic-btn"
          onClick={() => history.push('/tours/availability')}
        >
          {'Got it! Take me to next step >'}
        </Button>
      </div>
    </div>
  );
};

export default Introduction;
