import React, { useContext } from 'react';
import {
  Switch, Route, useParams, useHistory, Redirect,
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CenterizedLayout from '../../layouts/CenterizedLayout';
import Welcome from './Welcome';
import Introduction from './Introduction';
import Availability from './Availability';
import AllSet from './AllSet';
import DataContext from '../../contexts/DataContext';
import PROFILE, { UPDATE_SETTING } from '../../queries/profile';

const TOURS_HEADINGS = {
  welcome: {
    title: 'Welcome to Dexter!',
    description: 'Dexter is a go-to marketplace for booking meetings with experts on demand',
  },
  introduction: {
    title: '3-Easy Steps to Booking Expert Interviews',
    description: `Dexter provides a seamless user experience for finding and
      booking meetings with the most relevant experts for your unique questions`,
  },
  availability: {
    title: 'Your Availability',
    description: `Tell us your standard work hours.
      Your Dexter meetings will only take place during these hours.
      Don't worry, you can always change them or set exceptions later.`,
  },
  allset: {
    title: 'That\'s It For Now!',
    description: `You are ready to get started!
      Check out one of our popular expert categories below or Go to Home`,
  },
};

const OnBoardingSteps = () => {
  const history = useHistory();
  const { page } = useParams();
  const { user } = useContext(DataContext);

  const [updateSetting] = useMutation(UPDATE_SETTING, {
    variables: { name: 'clientOnboarded', value: 'true' },
    update(cache, { data }) {
      cache.writeQuery({
        query: PROFILE,
        data: { me: data.updateSetting },
      });
    },
  });
  const onExitTour = (redirect) => {
    updateSetting().then(() => history.push(redirect));
  };

  const { title, description } = TOURS_HEADINGS[page];

  return (
    <CenterizedLayout title={title} description={description}>
      <div>
        <Switch>
          <Route path="/tours/welcome" component={Welcome} />
          <Route path="/tours/introduction" component={Introduction} />
          <Route path="/tours/availability" render={() => <Availability user={user} />} />
          <Route path="/tours/allset" render={() => <AllSet onExitTour={onExitTour} />} />
        </Switch>
      </div>
    </CenterizedLayout>
  );
};

const Tours = () => {
  const { user } = useContext(DataContext);

  const onboardedData = user.settings.filter((s) => s.name === 'clientOnboarded')[0];
  const isOnboarded = onboardedData.value === 'true';

  if (isOnboarded) {
    return <Redirect to="/" />;
  }
  return <OnBoardingSteps />;
};

export default Tours;
