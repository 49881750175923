import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Modal, List, Table, Icon,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { MEETING_INVITE, MEETING_REPORT } from '../../queries/meeting';
import Loading from '../Loading';
import MeetingSettings from './MeetingSettings';
import ZoomInfo from './ZoomInfo';
import ExpertInfo from './ExpertInfo';
import InterviewerInfo from './InterviewerInfo';
import DataContext from '../../contexts/DataContext';

const MeetingInvite = ({ jobId }) => {
  const { loading, data } = useQuery(MEETING_INVITE, {
    variables: { id: jobId },
  });
  if (loading) return <Loading />;
  return (
    <div>{data.job.zoomMeeting.meetingInvite}</div>);
};

const MeetingRecordings = ({ meetingReport }) => (
  <>
    {meetingReport.recordings.map((x) => (
      <Button
        key={x.contentType}
        onClick={() => {
          if (['Video', 'Audio'].includes(x.contentType)) {
            window.open(x.playUrl);
          }
          if (x.contentType === 'Transcript') {
            window.open().document.write(`<pre>${x.content}</pre>`);
          }
        }}
      >
        {x.contentType}
      </Button>
    ))}
  </>
);

const MeetingReport = ({ jobId }) => {
  const { loading, data } = useQuery(MEETING_REPORT, {
    variables: { id: jobId },
  });
  if (loading) {
    return <Loading />;
  }
  const { meetingReport } = data.job.zoomMeeting;
  if (meetingReport.status === 'Complete') {
    return (
      <>
        <MeetingRecordings meetingReport={meetingReport} />
        <Table basic="very" padded stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Icon name="user" />
                Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="sign in" />
                Join time
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="sign out" />
                Leave time
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="stopwatch" />
                Duration
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingReport.participants.map(
              (participant) => (
                <Table.Row key={`${participant.name}${participant.joinedAt}`}>
                  <Table.Cell>{participant.name}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment(participant.joinedAt).format('MM/DD/YYYY, hh:mm:ss A')}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment(participant.leftAt).format('MM/DD/YYYY, hh:mm:ss A')}
                  </Table.Cell>
                  <Table.Cell>{`${participant.numMinutes} minute(s)`}</Table.Cell>
                </Table.Row>
              ),
            )}
          </Table.Body>
        </Table>
      </>
    );
  }
  return '(None - Meeting did not take place)';
};

// TODO: The job should know if a meeting is upcoming or past.
// Shouldn't need to pass it in
const MeetingModal = ({ children, job, type }) => {
  const { user } = useContext(DataContext);
  const [zoomInfo, setZoomInfo] = useState(null);

  const {
    id, expert, project, meetingAt, zoomMeeting,
  } = job;
  const { meetingLink } = zoomMeeting;
  const meetingTime = momentTZ.tz(meetingAt, user.timeZone).format('LLLL z');
  const expertName = job.user.fullName || job.user.email;

  const fetchZoomInfo = () => {
    if (type === 'Upcoming') {
      setZoomInfo(<MeetingInvite jobId={id} />);
    } else {
      setZoomInfo(<MeetingReport jobId={id} />);
    }
  };

  return (
    <Modal
      closeIcon
      defaultOpen={false}
      onOpen={fetchZoomInfo}
      trigger={children}
      size="small"
      id="meeting-modal"
    >
      <Modal.Header>
        {project.title}
        {type === 'Upcoming' && <MeetingSettings job={job} />}
      </Modal.Header>
      <Modal.Content>
        <List>
          <List.Item>{meetingTime}</List.Item>
          <InterviewerInfo meeting={job} />
          <ExpertInfo
            name={expertName}
            title={expert.title}
            organization={expert.organization.name}
          />
          <ZoomInfo type={type} zoomInfo={zoomInfo} meetingLink={meetingLink} />
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default MeetingModal;
