import React from 'react';
import { useHistory } from 'react-router-dom';
import { Segment, Image, Button } from 'semantic-ui-react';

const Welcome = () => {
  const history = useHistory();
  return (
    <div>
      <Segment id="welcome">
        <Image
          centered
          size="big"
          src={`${process.env.REACT_APP_AWS_S3_URL}/welcome.png`}
        />
        <p>
          Dexter is designed with your unique voice-of-customer research needs in mind.
          Focus your time & energy on getting answers to your important business questions
          and leave everything else to us!
        </p>
      </Segment>
      <Button
        floated="right"
        className="basic-btn"
        onClick={() => history.push('/tours/introduction')}
      >
        {'Start with the tour >'}
      </Button>
    </div>
  );
};

export default Welcome;
