import React, { useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import momentTZ from 'moment-timezone';
import {
  Grid, Header, Icon, Button, List, Divider,
} from 'semantic-ui-react';
import moment from 'moment/moment';
import { MEETING } from '../queries/meeting';
import DataContext from '../contexts/DataContext';
import ZoomInfo from '../components/Meetings/ZoomInfo';
import RescheduleMeeting from '../components/Meetings/RescheduleMeeting';
import CancelJob from '../components/Meetings/CancelJob';
import ReportNoShow from '../components/Meetings/ReportNoShow';
import Loading from '../components/Loading';
import ExpertInfo from '../components/Meetings/ExpertInfo';
import InterviewerInfo from '../components/Meetings/InterviewerInfo';
import addToCalendar from '../utils/addToCalendar';

const Meeting = () => {
  const { user } = useContext(DataContext);
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(MEETING, {
    variables: { id },
  });

  if (loading) return (<Loading />);
  if (error) {
    return (
      <Grid container id="error" textAlign="center">
        <Grid.Row>
          <Header>
            <Icon name="ban" />
            Page Not Found
          </Header>
        </Grid.Row>
        <Grid.Row>
          Oops! The page you are looking for does not exist. It might have been moved or deleted.
        </Grid.Row>
        <Grid.Row>
          <Button className="primary-btn" onClick={() => history.push('/home')}>Home</Button>
        </Grid.Row>
      </Grid>
    );
  }
  const { job } = data;
  const handleAddToCalendar = () => {
    addToCalendar({ job, user });
  };
  const meetingTime = momentTZ.tz(job.meetingAt, user.timeZone);
  const disableModification = (job.overall_status !== 'Scheduled');
  const disableNoShowReport = (job.overall_status !== 'Scheduled'
    || moment().isBefore(job.meetingAt));
  const NoShowText = (
    <div id="report-no-show" className={disableNoShowReport ? 'disable-no-show' : null}>
      <Icon name="flag" />
      Report No-show
    </div>
  );

  return (
    <Grid
      container
      id="meeting"
    >
      <Grid.Row>
        You have a new event
      </Grid.Row>
      <Grid.Row>
        <Header>{job.project.title}</Header>
      </Grid.Row>
      <Grid.Row>
        <List>
          <InterviewerInfo meeting={job} />
        </List>
      </Grid.Row>
      <Grid.Row>
        <List>
          <ExpertInfo
            name={job.user.fullName}
            title={job.expert.title}
            organization={job.expert.organization.name}
          />
        </List>
      </Grid.Row>
      <Grid.Row>
        <div className="grid-container">
          <div className="meeting-datetime" style={{ width: '100%' }}>
            <Header as="h4">
              {job.meetingAt
                ? (
                  <>
                    <div>
                      {meetingTime.format('dddd, MMMM DD, YYYY')}
                    </div>
                    <div>
                      {`${meetingTime.format('hh:mm a')} - 
                    ${meetingTime.add(1, 'hour').format('hh:mm a')} ${meetingTime.format('z')}`}
                    </div>
                  </>
                ) : 'No Meeting Time'}
            </Header>
          </div>
          <div className="meeting-invite">
            <List id="meeting-invite-list">
              <ZoomInfo
                type="Upcoming"
                zoomInfo={job.zoomMeeting.meetingInvite}
                meetingLink={job.zoomMeeting.meetingLink}
              />
            </List>
          </div>
          <div className="action-btns">
            <List>
              <List.Item>
                <Button className="primary-btn" disabled={!job.meetingAt} onClick={handleAddToCalendar}>
                  <Icon name="calendar plus" />
                  Add to Calendar
                </Button>
              </List.Item>
              <List.Item>
                <RescheduleMeeting job={job}>
                  <Button className="basic-btn" disabled={disableModification}>
                    <Icon name="refresh" />
                    Reschedule
                  </Button>
                </RescheduleMeeting>
              </List.Item>
              <List.Item>
                <CancelJob job={job}>
                  <Button className="basic-btn" disabled={disableModification}>
                    <Icon name="trash" />
                    Cancel
                  </Button>
                </CancelJob>
              </List.Item>
            </List>
          </div>
          <div className="secondary-action-btns">
            {disableNoShowReport
              ? NoShowText
              : (
                <ReportNoShow job={job}>
                  {NoShowText}
                </ReportNoShow>
              )}

            <Link to="/home" id="view-upcoming-meetings">
              <Icon name="list" />
              View all upcoming meetings
            </Link>
          </div>
          <div className="pro-tip">
            <Divider horizontal><Header as="h4">Pro Tip</Header></Divider>
            <Header as="h5" id="pro-tip-subheader">Check multiple calendars for availability</Header>
            Dexter can check multiple calendars for conflicts and only offer
            the times you’re available
            <Link to="/settings/availability/external_calendars" id="pro-tip-calendar-link"> Add another calendar</Link>
          </div>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default Meeting;
