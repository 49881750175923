import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Header, Label, Segment, Button,
} from 'semantic-ui-react';

const TAGS = [
  'Liquid Biopsy', 'Single Cell', 'CRISPR',
  'Digital Pathology', 'Oncology', 'Gene Therapy',
  'MRD Testing', 'Ob/Gyn', 'COVID-19',
];

const AllSet = ({ onExitTour }) => {
  const history = useHistory();
  return (
    <div>
      <Segment id="allset">
        <Header>Popular Expert Categories</Header>
        <div className="category-section">
          {TAGS.map((t) => (
            <Label
              key={t}
              size="huge"
              as="a"
              basic
              onClick={() => {
                onExitTour({ pathname: '/search', search: `?query=${t}` });
              }}
            >
              {t}
            </Label>
          ))}
        </div>
      </Segment>
      <div>
        <Button className="basic-btn" onClick={() => history.push('/tours/availability')} content="<" />
        <Button
          floated="right"
          className="primary-btn"
          onClick={() => onExitTour('/home')}
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
};

export default AllSet;
