import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import AvailableTimeBlocks from '../../components/Settings/Availability/AvailableTimeBlocks';
import MessageContext from '../../contexts/MessageContext';

const Availability = ({ user }) => {
  const history = useHistory();
  const { setErrorMsg } = useContext(MessageContext);

  return (
    <div>
      <AvailableTimeBlocks user={user} />
      <div>
        <Button className="basic-btn" onClick={() => history.push('/tours/introduction')} content="<" />
        <Button
          floated="right"
          className="basic-btn"
          onClick={() => {
            if (user.timeZone) {
              history.push('/tours/allset');
            } else {
              setErrorMsg('Please select your timezone');
            }
          }}
        >
          {'I am done setting my availability >'}
        </Button>
      </div>
    </div>
  );
};

export default Availability;
